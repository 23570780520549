import { useState, useEffect } from "react";
import greenCollarImage from '../images/greenCollar.webp';
import MarkaAlım from '../images/sixteen.png';
import MarkaTescilDesteği from '../images/MarkaTescilDesteği.png';
import CountUp from 'react-countup';
import popup from "../images/popup1.png"; // Pop-up görseli

const statsData = [
  { label: 'Sektör', value: 20 },
  { label: 'Danışan', value: 350 },
  { label: 'Onay', value: 340 },
];

const Stats = () => {
  return (
    <div className="max-w-4xl mx-auto py-12 gap-5">
      <h2 className="text-3xl font-bold text-center mb-8">İstatiklerimiz</h2>
      <div className="gap-8 flex justify-center items-center text-6xl">
        {statsData.map((stat, index) => (
          <div key={index} className="text-center">
            <h3 className="text-4xl text-black">
              <CountUp end={stat.value} duration={2.5} suffix="+" />
            </h3>
            <p className="text-3xl font-medium">{stat.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const WhoWeAre = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Pop-up kontrolü için state

  // Sayfa yüklendiğinde pop-up'ı açmak için useEffect kullanımı
  useEffect(() => {
    setIsPopupOpen(true); // Sayfa yüklendiğinde pop-up'ı açar
  }, []); // Yalnızca bir kez, component mount olduğunda çalışır

  return (
    <div className="flex bg-slate-50">
      <div className="flex flex-col py-24 gap-16">
        {/* İçeriğiniz */}
        <div className="flex mx-4 lg:mx-10 lg:gap-10 flex-col-reverse lg:flex-row bg-slate-200 py-10 rounded-box">
          <div className="flex flex-col justify-center lg:w-2/3 px-10 gap-2">
            <h1 className="text-3xl font-bold">Şeffaflık ve Netlik ile Başarıya Ulaşın</h1>
            <p className="text-justify lg:text-xl">
              İş performansınızı şeffaf bir şekilde analiz eden detaylı raporlarımız, stratejik hedeflerinize ulaşmanız için net bir yol haritası sunar.
            </p>
          </div>
        </div>

        {/* Pop-up (Modal) */}
        {isPopupOpen && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" // z-index ekledik
            onClick={(e) => e.target === e.currentTarget && setIsPopupOpen(false)} // Pop-up dışına tıklanırsa kapatma
          >
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl text-center z-50"> {/* z-index ekledik */}
              {/* Pop-up İçinde Görsel */}
              <img
                src={popup} // Pop-up görseli
                alt="Açıklayıcı Görsel"
                className="w-auto h-auto rounded-lg mb-4"
              />

              <h2 className="text-xl font-bold mb-4">1-31 Mart 2025</h2>
              <p>Girişimci Destek Programı Başlamıştır.</p>

              <button
                onClick={() => setIsPopupOpen(false)} // Pop-up'ı kapatma
                className="mt-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition"
              >
                Kapat
              </button>
            </div>
          </div>
        )}

        {/* Diğer İçeriğiniz */}
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-5 py-8 px-5 lg:px-20'>
          {/* İçerikler */}
        </div>

        <div>
          <Stats />
        </div>

        {/* Daha Fazla İçerik */}
      </div>
    </div>
  );
};

export default WhoWeAre;
